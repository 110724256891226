import React from 'react';
import { Link } from 'react-router-dom';

const sideDrawer = (props) => {
	let drawerClasses = 'side-drawer';

	if (props.show) {
		drawerClasses = 'side-drawer open';
	}

	return (
		<div className={drawerClasses}>
			<ul>
				<li>
					<Link to="/" onClick={props.click}>
						Home
					</Link>
				</li>
				<li>
					<Link to="/drawings" onClick={props.click}>
						Drawings
					</Link>
				</li>
				<li>
					<Link to="/videos" onClick={props.click}>
						Videos
					</Link>
				</li>
			</ul>
		</div>
	);
};

export default sideDrawer;