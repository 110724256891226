import React, { Fragment } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import NavBar from "./NavBar";
import Footer from "../components/Footer";
import Drawings from "../components/Drawings";
// import Prophotos from "../components/Prophotos";
import Videos from "../components/Videos";
import Home from "../components/Home";

const App = () => (
  <Fragment>
    <Router>
      <div className="header">
        <NavBar />
      </div>
      <div className="container">
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/drawings" exact element={<Drawings />} />
          {/* <Route path="/hiddenphotos" exact component={Prophotos} /> */}
          <Route path="/videos" exact element={<Videos />} />
        </Routes>
      </div>
    </Router>
    <div className="footer-container">
      <Footer />
    </div>
  </Fragment>
);

export default App;
