import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import DrawerToggleButton from '../common/DrawerToggleButton';
import SideDrawer from '../common/SideDrawer';
import Backdrop from '../common/Backdrop';

import { closeSidedrawer, toggleSidedrawer } from '../store/actions';
import Logo from '../images/shira-logo.png';

class Navbar extends Component {
	// logout = (e) => {
	// 	e.preventDefault();
	// 	this.props.logout();
	// };

	render() {
		let backdrop;

		if (this.props.sideDrawerOpen) {
			backdrop = <Backdrop click={this.props.closeSidedrawer} />;
		}

		return (
			<nav className="navbar navbar-expand-sm justify-content-between">
				<div className="top_drawer-toggle-container">
					<DrawerToggleButton click={this.props.toggleSidedrawer} />
				</div>

				<SideDrawer
					show={this.props.sideDrawerOpen}
					click={this.props.closeSidedrawer}
					// currentUser={this.props.currentUser}
				/>

				{backdrop}
				<a className="navbar-brand" href="/">
					<img src={Logo} alt="Shira Home" />
				</a>
				<button
					className="navbar-toggler"
					type="button"
					data-toggle="collapse"
					data-target="#navbarNav"
					aria-controls="navbarNav"
					aria-expanded="false"
					aria-label="Toggle navigation"
				>
					<span className="navbar-toggler-icon" />
				</button>

				<div className="collapse navbar-collapse" id="navbarNav">
					<ul className="navbar-nav mr-auto">
						<li className="nav-item active">
							<a href="/" className="nav-link">
								Home<span className="sr-only">(current)</span>
							</a>
						</li>

						<li className="nav-item">
							<Link to="/drawings" className="nav-link">
								Drawings
							</Link>
						</li>
						<li className="nav-item">
							<Link to="/videos" className="nav-link">
								Videos
							</Link>
						</li>
					</ul>
				</div>
			</nav>
		);
	}
}

function mapStateToProps(state) {
	return {
		sideDrawerOpen: state.sideDrawer.sideDrawerOpen
	};
}

export default connect(mapStateToProps, { closeSidedrawer, toggleSidedrawer })(Navbar);