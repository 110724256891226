import React, { Component, Fragment } from "react";
import Modal from "react-modal";

import withGallery from "../common/withGallery";

import DrawingData from "../drawingData.json";

Modal.setAppElement("#root"); // suppresses modal-related test warnings.

// const srcPrefix = "../images/drawings/";
const srcPrefix = "/drawings/";

const drawingsIntro =
  "Shira loves drawing. Here are some of her drawings " +
  "since she was three. I used her own comment as " +
  "the title.";

class Drawings extends Component {
  // componentDidUpdate(prevProps, prevState) {
  //   console.log(`inside ShiraLanguage.js componentDidUpdate ... `);
  // }

  render() {
    let images = DrawingData;

    return (
      <Fragment>
        <p className="intro">{drawingsIntro}</p>
        <div className="image-container">
          {/* <div className="image-grid"> */}
          {images.map((image, i) => (
            <div key={i} className="image-item">
              {/* <div key={i} className="image-grid-item"> */}
              <p>
                <strong>{image.alt}</strong>
              </p>
              <p>{image.age}</p>
              {/* <img src={srcPrefix+this.props.pname + '/' + image.src}  */}
              <div className="frame">
                <img
                  src={srcPrefix + image.src}
                  // onClick={()=>this.props.openModal(image, i)}
                  alt={image.alt}
                />
              </div>
            </div>
          ))}
          <Modal
            isOpen={this.props.imageModalIsOpen}
            shouldCloseOnOverlayClick={true}
            onRequestClose={this.props.closeModal}
            className="modal-content"
            overlayClassName="modal-overlay"
          >
            <div
              id="close-modal"
              className="close"
              onClick={this.props.closeModal}
            >
              &times;
            </div>
            <div className="caption-container">
              <p id="caption">{this.props.modalImageTitle}</p>
            </div>
            <div className="prev-next">
              <button id="prev" onClick={this.props.openPrev}>
                Prev
              </button>
              <button id="next" onClick={this.props.openNext}>
                Next
              </button>
            </div>
            <div className="modal-image-container">
              <img src={this.props.modalImageName} alt="Drawings" />
            </div>
          </Modal>
        </div>
      </Fragment>
    );
  }
}

export default withGallery(Drawings, srcPrefix, DrawingData);
