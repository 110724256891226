import React from "react";

import ShiraHead from "../images/drawings/Shira.jpeg";

const Home = () => (
  <div>
    <h2>Shira Yang Lustig</h2>
    <div className="home-head">
      <img src={ShiraHead} alt="Shira Head Shot" />
    </div>
    <div className="intro">
      <p className="intro">
        Shira brought so much joy to our lives. As her mom, I developed this
        website to help you get to know her better. Shira and I hiked to her
        preschool in the forest every morning. So many of her stories took place
        on the 1.3-mile trail. Enjoy!
      </p>
    </div>
    <div className="video-layout">
      <div className="video-item">
        <div className="video-item-intro story-photo">
          <div>
            <img src="/photos/hero.jpeg" alt="Hero" />
          </div>
        </div>
        <div className="video-item-intro story-text">
          <p className="story-title">
            <strong>Find another hero</strong>
          </p>
          <p className="age">4 years 3 months</p>
          <div>
            <p className="shira-words">
              "Mummy, if someone fell down there, I'll get a ladder and save
              him." Shira pointed to a valley .
            </p>
            <p className="mom-words">"Wow, you are a HERO!"</p>
            <p className="shira-words">
              "If there are two people falling down there, I'll FIND ANOTHER
              HERO, so we could bring two ladders to save both of them."
            </p>
          </div>
        </div>
      </div>

      <div className="video-item">
        <div className="video-item-intro story-photo">
          <div>
            <img src="/photos/y.jpeg" alt="Big Y" />
          </div>
        </div>
        <div className="video-item-intro story-text">
          <p className="story-title">
            <strong>Y</strong>
          </p>
          <p className="age">4 years 2 months</p>
          <p>One day, at this fork, Shira cried out: "Y"!</p>
        </div>
      </div>

      <div className="video-item">
        <div className="video-item-intro story-photo">
          <div>
            <img src="/photos/poem.jpeg" alt="Poem" />
          </div>
        </div>
        <div className="video-item-intro story-text">
          <p className="story-title">
            <strong>First poem</strong>
          </p>
          <p className="age">3 years 11 months</p>
          <div>
            On a morning walk, Shira said something as beautiful as a poem.
            <div className="poem">
              <p>The Sun has set.</p>
              <p>The Moon has risen. </p>
              <p>Stars came out.</p>
              <p>The wind is blowing gently.</p>
              <p>The baby is about to sleep.</p>
            </div>
          </div>
        </div>
      </div>

      <div className="video-item">
        <div className="video-item-intro story-photo">
          <div>
            <img src="/photos/wheel.jpeg" alt="Ferris Wheel" />
          </div>
        </div>
        <div className="video-item-intro story-text">
          <p className="story-title">
            <strong>How much?</strong>
          </p>
          <p className="age">4 years 4 months</p>
          <p>
            We took Shira to the Ferris wheel for a ride. After the ride, Daddy
            asked: "Shira, one ticket is ten dollars, how much is three
            tickets?"
          </p>
          <p>"Thirty", Shira answered effortlessly.</p>
        </div>
      </div>
    </div>

    <div className="video-item">
      <div className="video-item-intro story-photo">
        <div>
          <img src="/photos/rock.jpeg" alt="rock" />
        </div>
      </div>
      <div className="video-item-intro story-text">
        <p className="story-title">
          <strong>Where is it from?</strong>
        </p>
        <p className="age">4 years 0 months</p>
        <div>
          <p className="shira-words">
            "Mummy, why are there trees in the forest?"
          </p>
          <p className="mom-words">
            "Well, there is soil in the forest. With the sun light and water
            from fog or rain, trees grow here."
          </p>
          <p className="shira-words">"Where is soil coming from?"</p>
          <p className="mom-words">
            "Soil came from rocks. Look, underneath the trees are all rocks. The
            Sun, wind, fog, and rain are constantly weathering rocks, peeling,
            washing and breaking them down into soil."
          </p>
          <p className="shira-words">"Where are rocks from?"</p>
          <p className="mom-words">
            "Eh, our Earth was originally mostly rocks."
          </p>
          <p className="shira-words">"Where is the Earth from?"</p>
          <p className="shira-words">
            "When was the first day of the Earth? ..."
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default Home;
