import { createStore } from 'redux';

import reducers from './reducers';

export function configureStore() {
	const store = createStore(
		reducers
	);

	return store;
}
