import React from "react";
import ResponsivePlayer from "./ResponsivePlayer";

const videoMeta = [
  {
    url: "/videos/bikeracing.mp4",
    // url: "/videos/IMG_1031.mov",
    title: "Racing Biking",
    age: "4 years 10 months",
    comment: "Shira is racing.",
  },
  {
    url: "../videos/countto300.mp4",
    title: "Count to 300 in Chinese",
    age: "3 years 8 months",
    comment:
      'Noticing a house had two garage doors, Shira started to say "2 garage doors, 3 garage doors, ... ". ' +
      "When she reached 99, the maximum number she knew, I helped her with the next few numbers " +
      '"100, 101, ... ", then she figured out the pattern right away and counted ' +
      "all the way up to 300 for the first time in her life. She did not even make a mistake!",
  },
  {
    url: "/videos/reading.mp4",
    title: "Reading a Chinese Book",
    age: "3 years 8 months",
    comment:
      "After getting up in the morning, Shira decided to read a book instead of waking up Mommy and Daddy.",
  },
  {
    url: "/videos/drawing.mp4",
    title: "Drawing",
    age: "3 years 10 months",
    comment: "One of Shira's favorite activities is drawing.",
  },
  {
    url: "/videos/speaking-french.mp4",
    title: "Speaking French",
    age: "3 years 11 months",
    comment:
      "Shira switched to LesKidz when she was 3 years 3 months. I don't speak French. Only her teacher knows how much French she knows by now.",
  },
  {
    url: "/videos/interview.mp4",
    title: "First Interview",
    age: "3 years 11 months",
    comment:
      "Daddy tested his microphone from work by interviewing Shira. It turned out Shira loved it!",
  },
  {
    url: "/videos/biking.mp4",
    title: "No More Training Wheels!",
    age: "4 years 0 months",
    comment: "Shira is biking in front of the Academy of Science.",
  },
];

const Videos = () => {
  let videoList = videoMeta.map((video) => (
    <div key={video.title} className="video-item">
      <ResponsivePlayer url={video.url} />
      <div className="video-item-intro">
        <p>
          <strong>{video.title}</strong>
        </p>
        <p>{video.age}</p>
        <p>{video.comment}</p>
      </div>
    </div>
  ));

  return <div className="video-layout">{videoList}</div>;
};

export default Videos;
